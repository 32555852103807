// @flow

import React from "react";
import { AssignmentLayout, Layout, withPrivateRoute } from "@containers";
import { ContactPersonsForm } from "@components/Assignment";

const AddContactPersons = (props: *) => (
    <Layout {...props}>
        <AssignmentLayout title="Ontvangers verslagen" hideFooterDetails>
            <ContactPersonsForm {...props} />
        </AssignmentLayout>
    </Layout>
);

export default withPrivateRoute(
    AddContactPersons,
    "/opdrachten/:inspectionId/contactpersonen/toevoegen",
);
